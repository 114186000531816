import React, { useEffect } from 'react';
import Typography from '@/components/Typography';
import CheckedIcon from '@/assets/svg/File-checked-icon.inline.svg';
import CrossIcon from '@/assets/svg/Cross-icon.inline.svg';
import UploadIcon from '@/assets/svg/Upload-icon.inline.svg';
import { useStateMachine } from 'little-state-machine';
import Container from '@/features/HeroAlternate/Container';
import updateAction from '@/features/HeroAlternate/actions';
import toast from 'react-hot-toast';
import { fileSchema } from '@/features/HeroAlternate/schema';

const Step0 = () => {
  const dropzoneRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { actions, state } = useStateMachine({ updateAction });

  useEffect(() => {
    const element = dropzoneRef.current;
    const input = inputRef.current;
    if (element && input) {
      [
        'drag',
        'dragstart',
        'dragend',
        'dragover',
        'dragenter',
        'dragleave',
        'drop',
      ].forEach(event => {
        element.addEventListener(event, e => {
          e.preventDefault();
          e.stopPropagation();
        });
      });

      ['dragenter', 'dragover', 'drop'].forEach(eventName => {
        element.addEventListener(eventName, () => {
          element.classList.add('drop');
        });
      });
      // ['dragleave', 'drop'].forEach(eventName => {
      //   element.addEventListener(eventName, () => {
      //     element.classList.remove('drop');
      //   });
      // });
      element.addEventListener('drop', e => {
        const dataTransfer = new DataTransfer();
        if (e.dataTransfer) {
          const file = e.dataTransfer.files[0];
          const isValid = fileSchema.safeParse({ file });
          if (!isValid.success) {
            const { message } = JSON.parse(isValid.error.message)[0];
            toast.error(message);
            return;
          }
          dataTransfer.items.add(file);
          input.files = dataTransfer.files;

          actions.updateAction({
            fileMounted: true,
            form: {
              ...state.form,
              file,
            },
          });
        }
      });
    }
  }, []);
  return (
    <Container ref={dropzoneRef} className="py-[21px] lg:py-12">
      {state.fileMounted ? (
        <div>
          <Typography
            variant="body-text-1"
            as="h3"
            className="flex flex-col items-center justify-center gap-y-3 text-yellow lg:flex-row lg:gap-x-2.5"
          >
            <CheckedIcon />
            {inputRef.current?.files && inputRef.current?.files[0].name}
            <button
              type="button"
              onClick={() => {
                if (inputRef.current) {
                  dropzoneRef.current?.classList.remove('drop');
                  inputRef.current.files = null;
                  inputRef.current.value = '';
                  actions.updateAction({
                    fileMounted: false,
                    form: {
                      ...state.form,
                      file: null,
                    },
                  });
                }
              }}
            >
              <CrossIcon width={16} height={16} fill="white" />
            </button>
          </Typography>
        </div>
      ) : (
        <>
          <UploadIcon width={50} height={38} />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            htmlFor="upload"
            className="cursor-pointer pt-4 pb-3.5 text-center"
          >
            <Typography
              as="span"
              variant="body-text-1"
              className=" text-center text-yellow"
            >
              Wgraj plik PDF z dysku lub <br className="lg:hidden" />
              przeciągnij go tutaj
            </Typography>
          </label>
          <Typography
            as="p"
            variant="body-text-3"
            className="text-center !font-normal text-light-gray"
          >
            Maksymalny rozmiar pliku 50 MB.
          </Typography>
        </>
      )}
      <input
        ref={inputRef}
        id="upload"
        name="upload"
        type="file"
        className="hidden"
        accept="application/pdf"
        onChange={e => {
          const { files, value } = e.target;
          if (!value) {
            actions.updateAction({
              fileMounted: false,
              form: {
                ...state.form,
                file: null,
              },
            });
            e.target.value = '';
            e.target.files = null;
            dropzoneRef.current?.classList.remove('drop');
            return;
          }
          if (files) {
            const isValid = fileSchema.safeParse({ file: files[0] });

            if (!isValid.success) {
              const { message } = JSON.parse(isValid.error.message)[0];
              toast.error(message);
              return;
            }
            dropzoneRef.current?.classList.add('drop');
            actions.updateAction({
              fileMounted: true,
              form: {
                ...state.form,
                file: files[0],
              },
            });
          }
        }}
      />
    </Container>
  );
};

export default Step0;
