import { z } from 'zod';
import { validatePhoneDebounced } from '@/utils/helpers/validatePhone';

const prev = {
  phone: '',
  isGood: false,
};

export const fileSchema = z.object({
  file:
    typeof window === 'undefined' // this is required if your app rendered in server side, otherwise just remove the ternary condition
      ? z.any()
      : z
          .instanceof(File)
          .refine(file => file !== null, {
            message: 'Plik jest wymagany.',
          })
          .refine(
            file => {
              const fileType = file?.type || '';
              return fileType === 'application/pdf';
            },
            {
              message: 'Plik nie jest w formacie PDF.',
            }
          )
          .refine(
            file => {
              const fileSize = file?.size || 0;
              return fileSize <= 50000000;
            },
            { message: 'Plik jest za duży.' }
          ),
});

export const formSchema = z.object({
  password: z.string(),
  email: z.string().email({ message: 'Wpisz poprawny adres e-mail.' }),
  phone: z.string().refine(
    async phone => {
      if (prev.phone !== phone) {
        prev.phone = phone;
        const isValidPhone = await new Promise(resolve =>
          // eslint-disable-next-line no-promise-executor-return
          validatePhoneDebounced(phone, resolve)
        );
        prev.isGood = isValidPhone as boolean;
        return isValidPhone;
      }
      return prev.isGood;
    },
    { message: 'Wprowadź poprawny numer telefonu.' }
  ),
  agreements: z.string(),
});

export type Form = z.infer<typeof fileSchema> & z.infer<typeof formSchema>;
