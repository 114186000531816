import * as React from 'react';
import Layout from '@/components/Layout';
import Seo from '@/components/Seo';
import Hero from '@/features/HeroAlternate';
import Features from '@/components/Features';
import Steps from '@/components/Steps';
import Preview from '@/components/Preview';
import Movie from '@/components/Movie';
import { createStore, StateMachineProvider } from 'little-state-machine';
import { useInView } from 'react-intersection-observer';
import { lazy } from 'react';
import { MainPage } from '@/utils/data/seo';

const ExampleReports = lazy(() => import('@/components/ExampleReports'));
const Testimonials = lazy(() => import('@/components/Testimonials'));

createStore(
  {
    form: {
      file: null,
      password: '',
      email: '',
      phone: '',
      agreements: true,
    },
    step: 0,
    fileMounted: false,
  },
  {
    name: 'Hero',
    persist: 'none',
  }
);

const IndexPage = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <Layout>
      <StateMachineProvider>
        <Hero />
      </StateMachineProvider>
      <Features />
      <Steps />
      <Preview />
      <Movie />
      <div ref={ref}>
        {inView && typeof window !== 'undefined' && (
          <React.Suspense fallback={<div />}>
            <ExampleReports />
            <Testimonials />
          </React.Suspense>
        )}
      </div>
    </Layout>
  );
};

export const Head = () => {
  return <Seo title={MainPage.title} description={MainPage.description} />;
};

export default IndexPage;
